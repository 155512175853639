<div class="box-sidebar" [ngClass]="{'view':app.menuAction}" [ngStyle]="{'height.vh':boxMenu}">
    <span class="float-end  mt-3 menu-close" (click)="closeMenu()">
        <span class="material-symbols-outlined" (click)="closeMenu()"
            [ngClass]="{'menuHide': !app.menuAction,'menuView': !app.menuAction}">
            clear
        </span>
    </span>
    <br>
    <br>
    <div class="box-item" [ngStyle]="{'height.vh':contentHeight}">
        <ul>
            <ng-container *ngFor="let menu of menuItem">
                <li [ngClass]="{'active': menu.active == true}" (click)="toURL(menu)">
                    <div class="d-flex">
                        <span class="material-symbols-outlined">
                            {{menu.Icon}}
                        </span>
                        <span class="ms-2">{{menu.Name}}</span>

                        <span class="material-symbols-outlined view" *ngIf="menu.active && menu?.sub?.length > 0">
                            keyboard_arrow_down
                        </span>
                        <span class="material-symbols-outlined view" *ngIf="!menu.active && menu?.sub?.length > 0">
                            keyboard_arrow_right
                        </span>

                    </div>

                </li>
                <ng-container *ngFor="let sub of menu.sub">
                    <li *ngIf="menu.active == true" [ngClass]="{'active': sub.active == true}" class="sub"
                        (click)="toSub(menu,sub)">
                        <div class="d-flex">
                            <span class="ms-2">{{sub.SubName}}</span>
                        </div>
                    </li>
                </ng-container>
            </ng-container>
        </ul>
    </div>
</div>
<div class="box-menu" *ngIf="!app.menuAction">
    <span class="material-symbols-outlined" (click)="viewMenu()"
        [ngClass]="{'menuHide': !app.menuAction,'menuView': !app.menuAction}">
        menu
    </span>
</div>