<div class="container">
    <div class="row">
        <div class="col-12">
            <h1>
                <button pButton label="BACK" class="p-button-text p-button-sm p-button-raised" (click)="back()">
                    <span class="material-symbols-outlined">
                        arrow_back
                    </span>
                </button>
                BASKET
            </h1>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p-table [value]="basket.items" [tableStyle]="{ 'min-width': '50rem' }">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Action</th>
                        <th>System</th>
                        <th>Package</th>
                        <th>License</th>
                        <th></th>
                        <th class="text-end">Price</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-items>
                    <tr>
                        <td> <span class="material-symbols-outlined m-action text-danger" (click)="remove(items)">
                                close
                            </span></td>
                        <td>

                            {{items.system_name}}
                        </td>
                        <td>{{items.package_name }}</td>
                        <td>{{items.license }}</td>
                        <td>{{items.package_type}} Month</td>
                        <td class="text-end">{{ items.package_price | number:'1.2-2' }} บาท </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr>
                        <td colspan="6" class="text-end">{{priceTotal | number:'1.2-2' }} บาท</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-12 text-end">
            <button *ngIf="!this.packageid" pButton label="Make Payment"
                class="p-button-text p-button-sm p-button-raised" (click)="createLicense()">
                <span class="material-symbols-outlined me-2">

                </span>
            </button>
            <button *ngIf="this.packageid" pButton label="Make Payment 2"
                class="p-button-text p-button-sm p-button-raised" (click)="createLicenseBySystem()">
                <span class="material-symbols-outlined me-2">

                </span>
            </button>
            <!-- <button pButton label="Make Payment" class="p-button-text p-button-sm p-button-raised" (click)="payment()">
                <span class="material-symbols-outlined me-2">
                    payments
                </span>
            </button> -->
        </div>
    </div>
</div>