<!-- <table class="table">
    <tr class="subHeader d-none d-md-table-row">
        <th>System</th>
        <th>License</th>
        <th class="text-end"><span *ngIf="modelItems.Role == 0 || modelItems.Role == 1">Action</span></th>
    </tr>
    <tr class="d-none d-md-table-row" *ngFor="let ln of modelItems.Lincens">
        <td>{{ln.System_name}}</td>
        <td>{{ln.License}}</td>
        <td class="text-end"><span class="text-danger move-action"
                *ngIf="modelItems.Role == 0 || modelItems.Role == 1">Revoke</span></td>
    </tr>
</table> -->
<ng-container *ngFor="let ln of modelItems.Lincens">
    <div class="box-license mt-2">
        <label>{{ln.System_name}}</label>
        <br>
        <span>{{ln.License}}</span>
        <br>
        <span class="text-danger move-action" *ngIf="modelItems.manageRole == 0 || modelItems.manageRole == 1"
            (click)="revoke(ln)">Revoke</span>
    </div>
    <hr>
</ng-container>