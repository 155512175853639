import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { AppService } from 'src/app/services/app.service';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  constructor(private app: AuthService,
    public sv: AppService,
    private http: HttpClient,
    private kc: KeycloakService) {
    this.getProfile();

  }
  profile: any;
  contact: boolean = false;
  viewContact() {
    this.contact = !this.contact
    this.profile.view = !this.profile.view
  }

  getProfile() {
    this.http.get("Profile").subscribe({
      next: (res: any) => {
        this.profile = res.data
        this.profile.view = false
      }
    })
  }

  closeProfile() {
    this.profile.view = false;
  }

  toDashoboard() {
    this.app.setTo('https://dashboard.legitid.org')
  }
}
