import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  pageIndex: number = 0
  pageTotal: number = 0
  @Input("page") set page(v: number) {
    this.pageIndex = v
  }
  @Input("pageTotal") set total(v: number) {
    this.pageTotal = v
    console.log(this.pageTotal);

  }

  @Output("updatePage") updatePage = new EventEmitter()


  pageReturn(status: string) {
    if (status == 'previous') this.pageIndex -= 1
    else this.pageIndex += 1
    this.updatePage.emit({ page: this.pageIndex })
  }
}
