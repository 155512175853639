<ng-container *ngIf="profile">
    <p-dialog [(visible)]="profile.view" [breakpoints]="{ '960px': '90vw' }" [style]="{ width: '50vw' }"
        (onHide)="close()">
        <div class="box-item">
            <div class="row m-0 ">
                <div class="col-12 p-0">
                    <div class="header bg">
                        <div class="d-flex">
                            <div class="box-image">
                                <img src="../../../../../../assets/icons/logo.png">
                            </div>
                            <div class="box-name ms-3">
                                <b>{{profile?.Name}}</b>
                                <br>
                                <span>USER</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12 mt-3">
                    <b>MY ACCOUNT</b>
                </div>
                <div class="col-12 ">
                    ID: {{profile.Sub}}
                </div>
                <!-- <div class="col-12 ">
                    USER: PIAO1234
                </div> -->
            </div>
            <hr>
            <div class="row m-0">
                <div class="col-12">
                    <b>ACTIVITY</b>
                </div>
                <div class="col-12 ">
                    Message
                </div>
                <div class="col-12 ">
                    Recover Password
                </div>
            </div>
            <hr>
            <div class="row m-0 mt-3">
                <div class="col-12 text-center">
                    <button class="logout" (click)="logout()">Logout</button>
                </div>
            </div>
            <br>
        </div>
    </p-dialog>
</ng-container>