export const AppURL = {
    organization: 'organization',
    teams: 'teams',
    package: 'package',
    onsidepackage: 'onside-package',
    license: 'license',
    activelicnens: 'active-licesne',
    basket: 'basket',
    check: 'check',
    invoice: 'invoice',
    payment: 'payment',
    ui: 'ui',
    profile: 'profile'
};