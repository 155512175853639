<div class="row">
    <div class="col-4">
        <button class="btn btn-sec sm icon-start " [disabled]="1 == pageIndex" (click)="pageReturn('previous')">
            <span class="material-symbols-outlined">
                arrow_left_alt
                </span>
            Previous
        </button>
    </div>
    <div class="col-4 text-center pt-2">
        Page {{pageIndex}} of {{pageTotal}}
    </div>
    <div class="col-4 text-end">
        <button class="btn btn-primary sm icon-end" [disabled]="pageTotal == pageIndex" (click)="pageReturn('next')">
            Next
            <span class="material-symbols-outlined">
                arrow_right_alt
                </span>
        </button>
    </div>
</div>