import { Component } from '@angular/core';
import { Basket, BasketItems } from './basket';
import { ActivatedRoute, Router } from '@angular/router';
import { AppURL } from 'src/app/app.url';
import { HttpClient } from '@angular/common/http';
import { AlertService } from 'src/app/services/alert.service';
import { ogBasketItems } from './basket.interface';
import { AppService } from 'src/app/services/app.service';
import { BackItems } from 'src/app/shareds/license/license';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})
export class BasketComponent {
  basket: Basket = { items: [] };
  priceTotal: number = 0
  packageid: string = ""
  ogid: string = ""
  BackSystem!: BackItems
  constructor(private router: Router,
    private http: HttpClient,
    private ac: ActivatedRoute,
    private alert: AlertService,
    private app: AppService) {
    this.packageid = this.ac.snapshot.params['packageid']
    this.ogid = this.ac.snapshot.params['ogid']
    if (this.packageid) {
      this.app.viewPage = false;
      this.getSystem();
    }

    let basket: any = localStorage.getItem('basket')
    let basketItems: any = JSON.parse(basket)
    basketItems.forEach((f: BasketItems) => {
      this.basket.items.push(f)
      this.priceTotal += Number(f.package_price)
    });
  }

  getSystem() {
    this.http.get(`system/${this.packageid}`).subscribe({
      next: (res: any) => {
        // this.logo = environment.contentPackage + res.data[0].System_logo
        this.BackSystem = res.data[0]
      }
    })
  }


  createLicense() {
    let model = {
      items: this.basket.items,
      total: this.priceTotal,
      Redirect_url: window.location.origin + '/payment/success',
      Redirect_url_fail: window.location.origin + '/invoice',
    }

    this.http.post(`createLicense`, model).subscribe({
      next: (res: any) => {
        this.basket.items = []
        window.location.replace(res.data.pay_content)
      },
      error: (err) => {
        this.alert.dialog(err.error.message)
      },
      complete() {
        localStorage.removeItem('basket')
      }
    })
  }

  createLicenseBySystem() {
    let model = {
      items: this.basket.items,
      total: this.priceTotal,
      Redirect_url: window.location.origin + `/payment/success/${this.packageid}/${this.ogid}`,
      Redirect_url_fail: window.location.origin + `/basket/by/${this.packageid}/${this.ogid}`,
    }

    this.http.post(`createLicense`, model).subscribe({
      next: (res: any) => {
        this.basket.items = []
        window.location.replace(res.data.pay_content)
      },
      error: (err) => {
        this.alert.dialog(err.error.message)
      },
      complete() {
        // localStorage.removeItem('basket')
      }
    })
  }

  remove(items: BasketItems) {
    this.alert.confirm('ต้องการลบ Package นี้ ?').then(fm => {
      if (fm.isConfirmed) {
        this.priceTotal = 0;
        this.basket.items.splice(this.basket.items.findIndex((d: any) => d == items), 1);
        this.basket.items.forEach((f: BasketItems) => {
          this.priceTotal += parseInt(f.package_price)
        })

        localStorage.setItem('basket', JSON.stringify(this.basket.items))
      }
    })

  }

  back() {
    if (this.packageid) this.router.navigate(['/', AppURL.onsidepackage, this.packageid, this.ogid])
    else this.router.navigate(['/', AppURL.package])

  }
}
