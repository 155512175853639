import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { GetInvoice } from './invoice.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent {
  constructor(private http: HttpClient) {
    this.getInvoice();
  }

  InvoiceItems!: GetInvoice[]
  pageTotal: number = 0
  getInvoice(e?: any) {
    console.log(e);
    let page = 1
    if (e) page = e.page
    this.http.get(`getinvoice/${page}/15`).subscribe({
      next: (res: any) => {
        this.InvoiceItems = res.data
        this.pageTotal = res.pageTotal

      }
    })
  }

  Payment(item: GetInvoice) {
    console.log(item);
    let model: any = item
    model.Redirect_url = window.location.origin + '/payment/success'
    model.Redirect_url_fail = window.location.origin + '/invoice'
    model.Inv_ref = item.Inv_ref
    this.http.post(`ksher/payment`, model).subscribe({
      next: (res: any) => {
        // console.log(res);
        window.location.replace(res.data.pay_content)
      }
    })
  }
}
