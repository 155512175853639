<!-- <router-outlet></router-outlet>
<app-toast></app-toast>
<app-loading></app-loading> -->
<!-- <ng-container *ngIf="app.viewPage">
    <app-navbar></app-navbar>
    <div class="side">
        <div
            [ngClass]="{'on':app.menuAction, 'off':!app.menuAction ,'pc':  checkWidth > 431,'mobile': 431 > checkWidth}">
            <app-sidebar></app-sidebar>
        </div>
    </div>
    <div class="box-content"
        [ngClass]="{'action':app.menuAction, 'hide':!app.menuAction,'pc':  checkWidth > 431,'mobile': 431 > checkWidth}">
        <br><br>
        <router-outlet></router-outlet>
    </div>
</ng-container> -->

<!-- <ng-container>
    <br><br>
    <router-outlet *ngIf="!app.viewPage"></router-outlet>
</ng-container> -->
<div class="box-content">
    <div class="view-menu" [ngClass]="{'d-none':side || !app.viewPage}">
        <span class="material-symbols-outlined" (click)="side = true">
            menu
        </span>
    </div>
    <div class="sidebar" [ngClass]="{'on':side, 'd-none':!app.viewPage}">
        <app-sidebar2 (updateSide)="side = false"></app-sidebar2>

    </div>
    <div class="box-content ">
        <div class="content">
            <br><br><br>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>