<div class="container" *ngIf="profile">
    <div class="row">
        <div class="col-12">
            <img src="../../../assets/icons/profile.png" alt="">
        </div>
        <div class="col-12 mt-3">
            <p><b>{{profile.Name}}</b></p>
            <p>{{profile.Email}}</p>
            <p class=" text-id">ID: {{profile.Sub}}
                <span class="material-symbols-outlined ms-2 m-action" (click)="copyText(profile.Sub)">
                    content_copy
                </span>
            </p>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-xl-6 col-lg-6 col-12">
            <hr>
            <h5>Secret Key</h5>
            <p class=" text-id">Member ID: {{secretItem.ServiceId}}
                <span class="material-symbols-outlined ms-2 m-action" (click)="copyText(secretItem.ServiceId)">
                    content_copy
                </span>
            </p>
            <p class=" text-id">Member Secret Key: {{secretItem.SecretKey}}
                <span class="material-symbols-outlined ms-2 m-action" (click)="copyText(secretItem.SecretKey)">
                    content_copy
                </span>
            </p>
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-12">
            <button class="btn btn-secondary lg icon-start" (click)="updateSecret()">
                <span class="material-symbols-outlined">
                    key
                </span>
                Change Secret Key
            </button>
        </div>
    </div>
    <br>
    <div class="row justify-content-center ">
        <div class="col-12">
            <button class="btn btn-secondary lg icon-start" (click)="logout()">
                <span class="material-symbols-outlined">
                    logout
                </span>
                LOGOUT
            </button>
        </div>
    </div>
</div>