<div class="container">
    <div class="row">
        <div class="col-12 text-center text-success ">
            <span class="material-symbols-outlined done">
                task_alt
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <h1>Payment completed</h1>
            <br>
            <!-- <a href="https://dashboard.legitid.org/license">Go to the license repository</a> -->
            <p class="text-decoration-underline text-info m-action" (click)="viewLicense()">Go to the license repository
            </p>
        </div>
    </div>
</div>