import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Members, teamSystem, teamSystemAction, temsItems } from '../team.interface';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-sys-role',
  templateUrl: './sys-role.component.html',
  styleUrls: ['./sys-role.component.scss']
})
export class SysRoleComponent {
  role: number = 1
  modelItems!: Members
  ogId: number = 0
  @Input('model') set model(v: Members) {
    this.modelItems = v;
    console.log(this.modelItems);
    this.ogId = this.ac.snapshot.params['ogid']
    if (this.modelItems) {
      this.GetSystem()

    }

  }
  @Output('updateModel') updateModel = new EventEmitter()


  constructor(private http: HttpClient,
    private ac: ActivatedRoute,
    private alert: AlertService
  ) {

  }
  content: string = environment.contentPackage
  systemItems!: teamSystem[]
  GetSystem() {
    this.http.get(`system`).subscribe({
      next: (res: any) => {
        this.systemItems = res.data

      },
      complete: () => {
        this.GetSystemRole();
      }
    })
  }

  systemRoleItems!: teamSystemAction[]
  GetSystemRole() {
    this.http.get(`team/SystemRoleByid/${this.ogId}/${this.modelItems.Userid}`).subscribe({
      next: (res: any) => {
        this.systemRoleItems = res.data
        if (this.systemRoleItems == null) return
        this.systemRoleItems.forEach((f: teamSystemAction) => {
          let find = this.systemItems.find((fn: teamSystem) => fn.System_id == f.System_id)
          if (find) find.System_view = true
        })
      }
    })
  }

  actionSystemRole(v: teamSystem) {
    v.System_view = !v.System_view
  }

  updateRole() {
    let find = this.systemItems.filter((fl: teamSystem) => fl.System_view == true)
    this.http.put(`team/updateSystemRole/${this.ogId}/${this.modelItems.Userid}`, find).subscribe({
      next: (res: any) => {
        this.updateModel.emit(true)
        this.alert.notify(res.message, 'success')
      }
    })
  }
}
