import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { profile } from './profile.interface';
import { KeycloakService } from 'keycloak-angular';
import { AlertService } from 'src/app/services/alert.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  profile!: profile
  constructor(private http: HttpClient,
    private kc: KeycloakService,
    private clipboard: Clipboard,
    private alert: AlertService
  ) {
    this.getProfile()
    this.getSecret();
  }

  getProfile() {
    this.http.get("Profile").subscribe({
      next: (res: any) => {
        this.profile = res.data
      }
    })
  }

  secretItem: any;
  getSecret() {
    this.http.get(`secret`).subscribe({
      next: (res: any) => {
        this.secretItem = res.data
      }
    })
  }

  updateSecret() {
    this.alert.confirm('If you change the secret key, previous codes will no longer be valid. Do you want to proceed with the change?').then((fm) => {
      if (fm.isConfirmed) this.http.put(`secret`, null).subscribe({
        next: (res: any) => {
          this.secretItem = res.data
          this.alert.notify(res.message,'success')
          this.getSecret();
        }
      })
    })

  }


  copyText(text: string) {
    this.clipboard.copy(text);
    this.alert.notify('Copy Text Successfull', 'success')
  }

  logout() {
    this.kc.logout()
  }
}
