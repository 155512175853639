import { Component, Input } from '@angular/core';
import { sysTm } from 'src/app/pages/company/company.interface';

@Component({
  selector: 'app-add-license',
  templateUrl: './add-license.component.html',
  styleUrls: ['./add-license.component.scss']
})
export class AddLicenseComponent {
  sys!: sysTm
  license: any = [
    { name: 'FKS1000' },
    { name: 'ALOS88888' },
  ]

  team: any = [
    { name: 'PIAO001' },
    { name: 'PIAO002' },
  ]

  licenseUse: string = ""
  @Input('model') set item(v: sysTm) {
    this.sys = v;
    console.log(this.sys);

  }
}
