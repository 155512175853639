<div class="logout">
    <button class="btn btn-sec lg icon-start" (click)="logout()">
        <span class="material-symbols-outlined">
            logout
        </span>
        Logout
    </button>
</div>
<div class="container box-ogz">
    <div class="row justify-content-center">
        <div class="col-md-6 col-12">
            <h1>Organization</h1>
            <button *ngIf="companyItem" class="btn btn-sec lg icon-start me-3 mb-3" (click)="ToDashbaord()">
                <span class="material-symbols-outlined">
                    dashboard
                </span>
                Dashboard
            </button>
            <button *ngIf="companyItem" class="btn btn-primary lg icon-start" (click)="actionView=true">
                <span class="material-symbols-outlined">
                    apartment
                </span>
                Create Organization</button>
        </div>
    </div>
    <div class="row justify-content-center" *ngFor="let cm of companyItem">
        <div class="col-md-6 col-12 ">
            <div class="shadow-sm p-3 mb-3 mt-3 bg-body action" (click)="toSystem(cm)">
                {{cm.Organization_name}}
            </div>
        </div>
    </div>
    <div class="row g-3 mt-5 justify-content-center" *ngIf="!companyItem">
        <div class="col-xl-6 col-lg-6 col-12 mt-3">
            <app-settig-company (updateItem)="updateItem($event)"></app-settig-company>
        </div>
    </div>
    <br><br><br>
</div>


<p-dialog header="Create Organization" [(visible)]="actionView" [breakpoints]="{ '960px': '90vw' }"
    [style]="{ width: '50vw' }" (onHide)="close()">
    <app-settig-company (updateItem)="updateItem($event)"></app-settig-company>
</p-dialog>