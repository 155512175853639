import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppURL } from 'src/app/app.url';
import { BasketItems, PackageHeader, PackageItems, PackageList, System } from './outside-package.interface';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';


@Component({
  selector: 'app-outside-package',
  templateUrl: './outside-package.component.html',
  styleUrls: ['./outside-package.component.scss']
})
export class OutsidePackageComponent {
  modelItem?: System
  packageItem: PackageList = { items: [] };
  sortAction: number = 1;
  packages: any = []
  packageid: string = ""
  ogid: string = ""
  path: string = ""
  constructor(private router: Router,
    private http: HttpClient,
    private ac: ActivatedRoute,
    private app: AppService) {
    this.app.viewPage = false
    this.packageid = this.ac.snapshot.params['packageid']
    this.ogid = this.ac.snapshot.params['ogid']
    this.path = this.ac.snapshot.queryParams['path']
    if (this.path) sessionStorage.setItem('ogback', this.path)
    if (this.packageid) {
      this.getPackageClass()
      this.getSystem();
    }
    if (sessionStorage.getItem('ogback')) {
      this.path = (<any>sessionStorage.getItem('ogback'))
    }

  }


  BackSystem: any[] = []
  getSystem() {
    this.http.get(`system/${this.packageid}`).subscribe({
      next: (res: any) => {
        this.BackSystem = res.data
      }
    })
  }


  orders: number = 0
  actionOrders: BasketItems[] = []
  actionLicense!: PackageItems;
  vieworders: boolean = false;
  objDefault: PackageItems = {
    "System_id": "",
    "System_name": "",
    "Package_id": 0,
    "Package_name": "",
    "Package_license": 0,
    "Package_price": 0,
    "Package_logo": "",
    "Package_promotion": 0,
    "Package_discount": 0,
    "Package_status": 0,
    "Package_type": "",
  }
  useLicense(item: PackageItems) {
    this.actionLicense = item;
    this.licenseAmount = 1
    this.packageAmount = this.actionLicense.Package_license
    this.priceAmount = this.actionLicense.Package_price
    this.vieworders = true;
  }

  classItems!: PackageHeader[]
  getPackageClass() {
    this.http.get(`package/headerview/${this.packageid}`).subscribe({
      next: (res: any) => {
        this.classItems = res.data
        this.sortAction = this.classItems[0].Class
        this.getPackage();
      }
    })
  }

  getPackage() {
    this.http.get(`package/view/${this.packageid}/${this.sortAction}`).subscribe({
      next: (res: any) => {
        // console.log(res.item);
        this.packageItem = res
        console.log(this.packageItem);

        this.packageItem.items.forEach((f: PackageItems) => {
          f.Package_logo = environment.contentPackage + '/' + f.Package_logo
        })
        let oders: any = localStorage.getItem('basket')
        if (localStorage.getItem('basket')) this.actionOrders = JSON.parse(oders)
      }
    })
  }

  licenseAmount: number = 1;
  packageAmount: number = 0
  priceAmount: number = 0
  updateAmount(v: any) {
    if (!this.actionLicense) return
    this.licenseAmount = v.value
    this.packageAmount = this.actionLicense.Package_license * this.licenseAmount
    this.priceAmount = this.actionLicense.Package_price * this.licenseAmount
  }

  addBasket() {
    if (localStorage.getItem('basket')) localStorage.removeItem('basket')
    for (let i = 0; i < this.licenseAmount; i++) {
      let model: BasketItems = {
        systemId: this.actionLicense?.System_id,
        packageId: this.actionLicense?.Package_id,
        system_name: this.actionLicense?.System_name,
        package_name: this.actionLicense?.Package_name,
        package_price: this.actionLicense?.Package_price,
        license: this.actionLicense?.Package_license,
        package_type: this.actionLicense?.Package_type,
        price: this.actionLicense?.Package_price
      }
      this.actionOrders.push(model)
    }
    this.actionLicense = this.objDefault
    this.vieworders = false;
    localStorage.setItem('basket', JSON.stringify(this.actionOrders))
  }



  viewBasket() {
    this.router.navigate(['/', AppURL.basket, 'by', this.packageid, this.ogid])
  }

  useSort(sort: number) {
    this.sortAction = sort
    this.getPackage()
  }

  back() {
    // this.packageid = this.BackSystem.find((fn: any) => fn.System_id == this.packageid).System_url
    // window.location.replace(`${this.packageid}`)
    this.router.navigate(['/', AppURL.activelicnens, this.packageid, this.ogid])
  }
}

