<div class="container">
    <div class="row">
        <div class="col-12">
            <!-- <button pButton class="p-button-text p-button-sm p-button-raised" label="Add Organization"
                (click)="AddCompany()">
                <span class="material-symbols-outlined">
                    add
                </span>
            </button> -->
            <button class="btn btn-primary lg icon-start rounded " (click)="AddCompany()">
                <span class="material-symbols-outlined">
                    add
                </span>
                Add Organization
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-xl-4 col-lg-6 col-12" *ngFor="let cm of companyItem">
            <div class="box-item p-3 mb-3">
                <div class="row mt-2">
                    <div class="col-6">
                        <b> {{cm.Organization_name}}</b>
                    </div>
                    <div class="col-6 text-end">
                        <img width="100" height="100" [src]="cm.Organization_logo" alt="">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12" *ngIf="cm.Organization_owner != 0">
                        <br>
                    </div>
                    <div class="col-6" *ngIf="cm.Organization_owner == 0">
                        <div class="d-flex start license  action" (click)="SettingCompany(cm)">
                            <span class="material-symbols-outlined me-1">
                                settings
                            </span>
                            <span>Organization</span>
                        </div>
                    </div>
                    <div class="col-6" *ngIf="cm.Organization_owner == 0">
                        <div class="d-flex license justify-content-end">
                            <span class="text-role">OWNER</span>
                        </div>
                    </div>

                </div>
                <div class="row mt-2">
                    <div class="col-6">
                        <div class="d-flex license start action" (click)="ToSystem(cm)">
                            <span class="material-symbols-outlined  me-1">
                                grid_view
                            </span>
                            <span>System</span>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="d-flex  license justify-content-end end action" (click)="Team(cm)">
                            <span class="material-symbols-outlined me-1">
                                groups
                            </span>
                            <span>Team</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog [header]="textHeader" [(visible)]="actionView" [breakpoints]="{ '960px': '90vw' }" [style]="{ width: '50vw' }"
    (onHide)="close()">
    <div class="row"
        *ngIf="!sysAction && actionUse == actionList.license || !sysAction && actionUse == actionList.system">
        <a *ngIf="systemItems.length == 0">Add system access rights</a>
        <div class="col-xl-4 col-lg-4 col-6 text-center" *ngFor="let sys of systemItems">
            <div class="shadow-sm p-3 mb-5 bg-body rounded action" (click)="Action(sys)">
                <div class="box-image text-center ">
                    <img [src]="sys.System_logo">
                </div>
                <p><b>{{sys.System_name}}</b></p>
            </div>
        </div>
    </div>
    <app-settig-company *ngIf="actionUse == actionList.company" [model]="company"
        (updateItem)="updateItem($event)"></app-settig-company>
    <app-add-license *ngIf="sysAction" [model]="sysAction"></app-add-license>
    <!-- <app-team *ngIf="actionUse == actionList.team" [model]="company"></app-team> -->
</p-dialog>