<div class="container">
    <div class="row">
        <div class="col-12">
            <h1>Invoice</h1>
        </div>
        <div class="col-12">
            <table class="table table-responsive  table-hover ">
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Invoice No.</th>
                        <th class="d-none d-md-table-cell">Due Date</th>
                        <th class="text-end d-none d-md-table-cell">Amount</th>
                        <th class="text-end">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let inv of InvoiceItems">
                        <tr class="subHeader">

                            <td>
                                <span *ngIf="inv.Inv_status == 1" class="text-warning">UNPAID</span>
                                <b><span *ngIf="inv.Inv_status == 2" class="text-success">PAID</span></b>
                                <span *ngIf="inv.Inv_status == 3" class="text-danger ">EXPIRE</span>
                            </td>
                            <td>{{inv.Inv_no}}</td>
                            <td class="d-none d-md-table-cell">{{inv.Inv_expire}}</td>
                            <td class="text-end d-none d-md-table-cell">{{inv.Inv_amount || 0 | number:'1.2-2'}} THB
                            </td>
                            <td class="text-end">
                                <button *ngIf="inv.Inv_status == 1" class="btn btn-primary sm rounded  icon-start" (click)="Payment(inv)">
                                    <span class="material-symbols-outlined">
                                        receipt_long
                                    </span>
                                    Pay
                                </button>
                                <!-- <span *ngIf="inv.Inv_status == 1"><span>ICON ดูลายละเอียด</span> || ICON จ่ายเงิน</span> -->
                                <!-- <span *ngIf="inv.Inv_status == 2" class="text-success "> จ่ายเงินเสร็จ</span> -->
                            </td>
                        </tr>
                        <!-- <tr *ngFor="let ls of inv.License">

                            <td>Lincens: <span>{{ls.LicenseKey}}</span> </td>
                            <td class="text-end"><span *ngIf="ls.active == 1"><b class="text-success ">Active</b></span>
                            </td>
                        </tr> -->
                    </ng-container>
                </tbody>
            </table>
            <app-pagination *ngIf="InvoiceItems" [page]="1" [pageTotal]="pageTotal"
                (updatePage)="getInvoice($event)"></app-pagination>
        </div>
    </div>
</div>
<br><br>