<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="row g-3">
                <div class="col-12 mb-2">
                    <button class="btn btn-secondary lg icon-start rounded" (click)="backTo()">
                        <span class="material-symbols-outlined">
                            arrow_back
                        </span>
                        BACK
                    </button>
                </div>
                <div class="col-12">
                    <div class="input-icon-start">
                        <input type="text" placeholder="Search User by ID" #userid (change)="findUser(userid)">
                        <span class="material-symbols-outlined">
                            search
                        </span>
                    </div>
                </div>
                <div class="col-12 mt-3" *ngIf="findItems && find == true">
                    <div class="box-search shadow-sm p-3 mb-3 bg-body">
                        <ng-container *ngIf="findItems.Status == false">
                            <button class="btn btn-primary lg  mb-2" (click)="addTeam(userid)">Add to Team</button>
                        </ng-container>
                        <ng-container *ngIf="findItems.Status == true ">
                            <p><b class="text-success">Team mates</b></p>
                        </ng-container>
                        <br>
                        <span>ID : {{findItems.Id}}</span>
                        <br>
                        <span>Name : {{findItems.Fullname}}</span>
                        <br>
                        <span>Email : {{findItems.Email}}</span>
                    </div>
                </div>
            </div>
            <br>

            <table class="table table-hover">
                <thead>
                    <tr>
                        <th colspan="2">Member</th>
                        <th class="d-md-table-cell d-none"></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let member of userItems">
                        <tr>
                            <td colspan="2">
                                <br>

                                <p> <span *ngIf="member.Role == 0" class="text-success">
                                        <b>OWNER</b></span>
                                    <span *ngIf="member.Role == 1" class="text-success"> <b>ADMIN</b>
                                    </span>
                                    <span *ngIf="member.Role == 2" class="text-success">
                                        <b>USER</b></span>
                                    {{member.UserName}}
                                </p>
                                <button class="btn btn-primary sm icon-start rounded  me-3 mb-3"
                                    (click)="OgRole(member)" *ngIf="roleItems <= 1 && member.Role !=0">
                                    <span class="material-symbols-outlined">
                                        manage_accounts
                                    </span>
                                    Organization Role
                                </button>
                                <button
                                    class="btn btn-secondary sm icon-start rounded me-3 mb-3 d-inline-block d-md-none"
                                    (click)="ViewLicense(member)" *ngIf="roleItems == 0 || roleItems == 1">
                                    <span class="material-symbols-outlined">
                                        license
                                    </span>
                                    License
                                </button>
                                <button class="btn btn-secondary sm icon-start rounded mb-3 me-3"
                                    (click)="SysRole(member)" *ngIf="roleItems == 0 || roleItems == 1">
                                    <span class="material-symbols-outlined">
                                        manage_accounts
                                    </span>
                                    System Role
                                </button>
                                <button class="btn btn-secondary sm icon-start rounded mb-3"
                                    (click)="RemoveUser(member)"
                                    *ngIf="member.Role != 0 && roleItems == 0 || member.Role != 0 && roleItems == 1">
                                    <span class="material-symbols-outlined">
                                        person_remove
                                    </span>
                                    Remove
                                </button>
                            </td>
                            <td class="text-end d-none d-md-table-cell">
                                <span class="material-symbols-outlined move-action " *ngIf="member.View"
                                    (click)="viewItem(member)">
                                    expand_more
                                </span>
                                <span class="material-symbols-outlined move-action" *ngIf="!member.View"
                                    (click)="viewItem(member)">
                                    chevron_right
                                </span>
                            </td>
                        </tr>
                        <ng-container *ngIf="member.View">
                            <tr class="subHeader d-none d-md-table-row">
                                <th>System</th>
                                <th>License</th>
                                <th class="text-end"><span *ngIf="roleItems == 0 || roleItems == 1">Action</span></th>
                            </tr>
                            <tr class="d-none d-md-table-row" *ngFor="let ln of member.Lincens">
                                <td>{{ln.System_name}}</td>
                                <td>{{ln.License}}</td>
                                <td class="text-end"><span class="text-danger move-action"
                                        *ngIf="roleItems == 0 || roleItems == 1"
                                        (click)="revoke(member,ln)">Revoke</span></td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>

<p-dialog [header]="textHeader" [(visible)]="actionView" [breakpoints]="{ '960px': '90vw' }" [style]="{ width: '50vw' }"
    (onHide)="close()">
    <div class="row">
        <app-og-role *ngIf="ogRoleItems" [model]="ogRoleItems" (updateModel)="update($event)"></app-og-role>
        <app-sys-role *ngIf="sysRoleItems" [model]="sysRoleItems" (updateModel)="update($event)"></app-sys-role>
        <app-sys-license *ngIf="viewLicens" [model]="viewLicens"></app-sys-license>
    </div>
</p-dialog>