<div class="container" *ngIf="profile">
    <div class="row">
        <div class="col-12 text-center">
            <img src="../../../assets/icons/profile.png" alt="">
        </div>
        <div class="col-12 text-center mt-3">
            <p><b>{{profile.Name}}</b></p>
            <p>{{profile.Email}}</p>
            <p class=" text-id">ID: {{profile.Sub}}
                <span class="material-symbols-outlined ms-2 m-action" (click)="copyText()">
                    content_copy
                </span>
            </p>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-12">
            <div class="wave"></div>
        </div>
    </div> -->
    <div class="row justify-content-center ">
        <div class="col-12 text-center">
            <button class="btn btn-secondary lg icon-start" (click)="logout()">
                <span class="material-symbols-outlined">
                    logout
                </span>
                LOGOUT
            </button>
        </div>
    </div>
</div>