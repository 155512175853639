import { Component } from '@angular/core';
import { BackItems, GetLicense, License, LicenseItems, LicenseName } from './license';
import { HttpClient } from '@angular/common/http';
import { Clipboard } from '@angular/cdk/clipboard';
import { AlertService } from 'src/app/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { AppURL } from 'src/app/app.url';
@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss']
})
export class LicenseComponent {
  systemcode: string = ""
  BackSystem!: BackItems
  og: string = ''
  constructor(private http: HttpClient,
    private clipboard: Clipboard,
    private alert: AlertService,
    private ac: ActivatedRoute,
    private app: AppService,
    private router: Router
  ) {
    this.og = this.ac.snapshot.params['og']
    this.systemcode = this.ac.snapshot.params['systemid']
    if (this.systemcode) {
      this.app.viewPage = false
      this.getSystem()
      this.getLincensBySystem();
    }
    else this.getLincens();
  }
  

  getSystem() {
    this.http.get(`system/${this.systemcode}`).subscribe({
      next: (res: any) => {
        // this.logo = environment.contentPackage + res.data[0].System_logo
        this.BackSystem = res.data[0]
        console.log(this.BackSystem);

      }
    })
  }


  lincensItems!: GetLicense[]
  viewLincens: boolean = false
  getLincens() {
    this.http.get('getLicense').subscribe({
      next: (res: any) => {
        this.lincensItems = res.data
        this.lincensItems.forEach((f: GetLicense) => {
          if (f.License) {
            this.viewLincens = true
            f.Header = true
            f.View = false
          }
        })
      }
    })
  }

  getLincensBySystem() {
    this.http.get(`getLicense/system/${this.systemcode}`).subscribe({
      next: (res: any) => {
        this.lincensItems = res.data
        this.lincensItems.forEach((f: GetLicense) => {
          if (f.License) {
            this.viewLincens = true
            f.Header = true
            f.View = false
          }
        })
      }
    })
  }

  copyText(item: LicenseItems) {
    this.clipboard.copy(item.LicenseKey);
    this.alert.notify('Copy Text Successfull', 'success')
  }

  backPage() {
    this.router.navigate(['/', AppURL.activelicnens, this.systemcode, this.og])

  }
}
