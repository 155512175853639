import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Members } from '../team.interface';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-og-role',
  templateUrl: './og-role.component.html',
  styleUrls: ['./og-role.component.scss']
})
export class OgRoleComponent {
  role: number = 1
  modelItems!: Members
  @Input('model') set model(v: Members) {
    this.modelItems = v;
    console.log(this.modelItems);
  }
  @Output('updateModel') updateModel = new EventEmitter()

  ogId: number
  constructor(private http: HttpClient,
    private ac: ActivatedRoute,
    private alert: AlertService
  ) {
    this.ogId = this.ac.snapshot.params['ogid']

  }

  actionRole(v: any) {
    this.modelItems.Role = Number(v.target.value)
    this.http.put(`team/updateRole/${this.ogId}`, this.modelItems).subscribe({
      next: (res: any) => {
        this.updateModel.emit(true)
        this.alert.notify(res.message, 'success')
      },
      error: (err) => {
        console.log(err);

      },
    })
  }
}
