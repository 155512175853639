import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { actionList, companyItem, sysItems, sysTm } from './company.interface';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AppURL } from 'src/app/app.url';





@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent {
  constructor(private http: HttpClient,
    private router: Router,

  ) {
    this.getCompany();
    // this.getSystem();

  }

  actionView: boolean = false
  companyItem!: companyItem[];
  getCompany() {
    this.http.get(`organizations`).subscribe({
      next: (res: any) => {
        this.companyItem = res.data;
        this.companyItem.forEach((f: companyItem) => {
          if (f.Organization_logo) f.Organization_logo = environment.contentOG + '/' + f.Organization_logo
        })
      }
    })
  }

  getSystem(item: any) {
    this.http.get(`system/og/${item.Organization_id}`).subscribe({
      next: (res: any) => {
        this.systemItems = res.data
        console.log(this.systemItems.length);
        console.log(this.systemItems);

        this.systemItems.forEach((f: sysItems) => {
          if (f.System_logo) f.System_logo = environment.contentPackage + '/' + f.System_logo
        })
      }
    })
  }

  textHeader: string = ""
  systemItems!: sysItems[]
  // [] = [
  //   { logo: '../assets/logo/POS_0.png', name: 'POS', url: '' },
  //   { logo: '../assets/logo/Booking X_0.png', name: 'Booking', url: '' },
  //   { logo: '../assets/logo/NLChat_0.png', name: 'NLChat', url: '' },
  //   { logo: '../assets/logo/Biz soft_0.png', name: 'Bizsoft', url: '' },
  //   { logo: '../assets/logo/SURVEY BOX_0.png', name: 'Survey', url: '' },
  //   { logo: '../assets/logo/DeeAuction_0.png', name: 'DeAuction', url: '' },
  // ]

  actionUse: string = "";
  actionList: actionList = {
    license: 'license',
    system: 'system',
    company: 'company',
    team: 'team'
  }
  AddLicense() {
    this.textHeader = "Which system license is used?"
    this.actionView = true;
    this.actionUse = this.actionList.license

  }


  ToSystem(item: any) {
    this.textHeader = "Which system do you go to?"
    this.actionView = true;
    this.actionUse = this.actionList.system
    this.company = item;
    this.getSystem(item)

  }

  Team(item: any) {
    // this.textHeader = "Team"
    // this.actionView = true;
    // this.actionUse = this.actionList.team
    // this.company = item;
    this.router.navigate(['/', AppURL.organization, AppURL.teams, item.Organization_id])

  }

  AddCompany() {
    this.textHeader = ""
    this.actionView = true;
    this.actionUse = this.actionList.company
    this.company = ""
  }

  company: any;
  SettingCompany(item: any) {
    this.textHeader = ""
    this.actionView = true;
    this.actionUse = this.actionList.company
    this.company = item;
  }


  sysAction: any
  Action(sys: any) {
    if (this.actionUse == this.actionList.license) {
      this.textHeader = "Add license and user"
      this.sysAction = sys
    }
    if (this.actionUse == this.actionList.system) {
      let URL = sys.System_url + `?og=${this.company?.Organization_id}`
      window.location.replace(URL)
    }
    if (this.actionUse == this.actionList.team) {

    }
  }

  updateItem(e: any) {
    this.getCompany();
    this.close()
  }


  groupAction: boolean = false
  setGroup() {
    this.groupAction = true
  }

  groupAdd: boolean = false
  addGroup() {
    this.groupAdd = true
  }


  close() {
    this.sysAction = ""
    this.actionUse = ""
    this.actionView = false
  }


}



