<div class="container">
    <div class="row p-0 m-0">
        <div class="col-xl-4 col-lg-4 col-md-6 col-12 box-sort">
            <!-- <button class="btn" [ngClass]="{'btn-sec':sortAction == 1}" (click)="useSort(1)">6 Month</button>
            <button class="btn" [ngClass]="{'btn-sec':sortAction == 12}" (click)="useSort(12)">Year</button> -->
            <button *ngFor="let cl of classItems" class="btn" [ngClass]="{'btn-sec':sortAction == cl.Class}"
                (click)="useSort(cl.Class)">
                <span>{{cl.Class}} Month</span>
            </button>
        </div>
        <div class="col-8 text-end" *ngIf="actionOrders.length > 0">
            <button pButton class="p-button-text p-button-sm p-button-raised" (click)="viewBasket()">
                <span class="basket-number me-1"> {{actionOrders.length}}</span>
                <span class="material-symbols-outlined">
                    shopping_cart
                </span>
            </button>
        </div>
    </div>
    <div class="row p-0 m-0">
        <div class="col-xl-4 col-lg-4 col-md-6 col-12 text-center" *ngFor="let pk of packageItem.items">
            <div class="shadow-sm p-3 mt-5 bg-body action box-package" (click)="useLicense(pk)">
                <h2>{{pk?.Package_name}}</h2>
                <hr>
                <h3>{{pk?.Package_price | number: '1.2-2'}} THB</h3>
                <p>{{pk?.Package_license}} License </p>
                <p>Each license is valid for {{pk.Package_type}} month.</p>
                <button class="btn btn-primary">Get This Plan</button>
            </div>
        </div>
    </div>
    <br><br><br>
    <div class="row justify-content-center">
        <div class="col-xl-2">
            <button class="btn btn-sec lg w-100 icon-auto justify-content-center " (click)="back()">
                <span class="material-symbols-outlined">
                    arrow_back
                </span>
                BACK</button>
        </div>
    </div>
    <br><br><br>
</div>



<p-dialog header="License" *ngIf="vieworders" [(visible)]="vieworders" [breakpoints]="{ '960px': '90vw' }"
    [style]="{ width: '50vw' }">
    <div class="row">
        <div class="col-12 text-center">
            <div class="box-image">
                <img [src]="actionLicense.Package_logo" alt="">
            </div>
            <br>
            <span>{{actionLicense.Package_name}}</span>
            <p>{{actionLicense.Package_license}} Lincens / {{actionLicense.Package_price}} บาท</p>
            <input type="number" [value]="licenseAmount" placeholder="1" pInputText (input)="updateAmount(am)" #am>
            <br>
            <br>
            {{packageAmount}} Lincens = {{priceAmount | number:'1.2-2'}} THB
            <br><br>
        </div>
        <div class="col-12 text-center ">
            <!-- <button pButton label="Buy Now" class="p-button-text p-button-sm p-button-raised" (click)="addBasket()">
            <span class="material-symbols-outlined me-2">
                payments
                </span>
        </button> -->
            <button pButton label="Add Basket" class="ms-3 p-button-text p-button-sm p-button-raised"
                (click)="addBasket()">
                <span class="material-symbols-outlined me-2">
                    shopping_cart
                </span>
            </button>
        </div>
    </div>
</p-dialog>