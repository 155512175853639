import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpConfig } from './configs/http.config';
import { ToastComponent } from './shareds/toast/toast.component';
import { LoadingComponent } from './shareds/loading/loading.component';
import { SimpleLayoutModule } from './layouts/simple-layout/simple-layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { CompanyComponent } from './pages/company/company.component';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { AddLicenseComponent } from './shareds/add-license/add-license.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettigCompanyComponent } from './shareds/settig-company/settig-company.component';
import { InputTextModule } from 'primeng/inputtext';
import { UploadFileComponent } from './shareds/upload-file/upload-file.component';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { TeamComponent } from './shareds/team/team.component';
import { TableModule } from 'primeng/table';
import { ToSystemComponent } from './pages/to-system/to-system.component';
import { PackageComponent } from './pages/package/package.component';
import { PkDetailComponent } from './pages/package/pk-detail/pk-detail.component';
import { LicenseComponent } from './shareds/license/license.component';
import { BasketComponent } from './pages/basket/basket.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { UseLicenseComponent } from './shareds/use-license/use-license.component';
import { CheckPaymentComponent } from './pages/check-payment/check-payment.component';
import { PaginationComponent } from './shareds/pagination/pagination.component';
import { OgRoleComponent } from './shareds/team/og-role/og-role.component';
import { SysRoleComponent } from './shareds/team/sys-role/sys-role.component';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { OutsidePackageComponent } from './shareds/outside-package/outside-package.component';
import { UiComponent } from './shareds/ui/ui.component';
import { SysLicenseComponent } from './shareds/team/sys-license/sys-license.component';
import { ProfileComponent } from './pages/profile/profile.component';


function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: 'https://api-dev.legitid.org/auth/',
        realm: 'Avesta',
        clientId: 'dashbaord',
        // clientId: 'bookingX',

      },
      initOptions: {
        onLoad: 'login-required',  // allowed values 'login-required', 'check-sso';
        // flow: "standard"          // allowed values 'standard', 'implicit', 'hybrid';
      },
    });
}

@NgModule({
  declarations: [
    AppComponent,
    CompanyComponent,
    AddLicenseComponent,
    SettigCompanyComponent,
    UploadFileComponent,
    TeamComponent,
    ToSystemComponent,
    PackageComponent,
    PkDetailComponent,
    LicenseComponent,
    BasketComponent,
    InvoiceComponent,
    UseLicenseComponent,
    CheckPaymentComponent,
    PaginationComponent,
    OgRoleComponent,
    SysRoleComponent,
    OutsidePackageComponent,
    UiComponent,
    SysLicenseComponent,
    ProfileComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingComponent,
    ToastComponent,
    SimpleLayoutModule,
    KeycloakAngularModule,
    DialogModule,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    TableModule,
    ToastModule

  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfig, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
