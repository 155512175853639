import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppURL } from './app.url';
import { CompanyComponent } from './pages/company/company.component';
import { ToSystemComponent } from './pages/to-system/to-system.component';
import { PackageComponent } from './pages/package/package.component';
import { LicenseComponent } from './shareds/license/license.component';
import { BasketComponent } from './pages/basket/basket.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { CheckPaymentComponent } from './pages/check-payment/check-payment.component';
import { TeamComponent } from './shareds/team/team.component';
import { UseLicenseComponent } from './shareds/use-license/use-license.component';
import { OutsidePackageComponent } from './shareds/outside-package/outside-package.component';
import { UiComponent } from './shareds/ui/ui.component';
import { ProfileComponent } from './pages/profile/profile.component';


const routes: Routes = [
  { path: '', redirectTo: AppURL.organization, pathMatch: 'full' },
  {
    path: AppURL.organization, children: [
      { path: '', component: CompanyComponent },
      { path: `${AppURL.teams}/:ogid`, component: TeamComponent },
    ]
  },
  {
    path: AppURL.package, children: [
      { path: '', component: PackageComponent },
    ]
  },
  {
    path: AppURL.onsidepackage, children: [
      { path: ':packageid/:ogid', component: OutsidePackageComponent },
    ]
  },
  {
    path: AppURL.basket, children: [
      { path: '', component: BasketComponent },
      { path: 'by/:packageid/:ogid', component: BasketComponent },
    ]
  },
  {
    path: AppURL.invoice, children: [
      { path: '', component: InvoiceComponent },
    ]
  },
  {
    path: AppURL.profile, children: [
      { path: '', component: ProfileComponent },
    ]
  },
  {
    path: AppURL.payment, children: [
      { path: '', component: CheckPaymentComponent },
      { path: 'success', component: CheckPaymentComponent },
      { path: 'success/:systemid/:ogid', component: CheckPaymentComponent },
    ]
  },
  {
    path: AppURL.license, children: [
      { path: '', component: LicenseComponent },
      { path: ':systemid/:og', component: LicenseComponent },
    ]
  },
  {
    path: AppURL.activelicnens, children: [
      { path: ':systemid/:og', component: UseLicenseComponent },
    ]
  },
  {
    path: AppURL.ui, children: [
      { path: '', component: UiComponent },
    ]
  },
  {
    path: `${AppURL.check}`, component: ToSystemComponent
  },
  { path: '**', loadComponent: () => import('./pages/notfound/notfound.component') }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
