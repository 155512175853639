import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BasketItems, Package, PackageHeader, PackageItems, PackageList, System } from '../package';
import { Router } from '@angular/router';
import { AppURL } from 'src/app/app.url';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-pk-detail',
  templateUrl: './pk-detail.component.html',
  styleUrls: ['./pk-detail.component.scss']
})
export class PkDetailComponent {
  modelItem?: System
  packageItem: PackageList = { items: [] };
  sortAction: number = 1;
  packages: any = [
    {
      package_price: 1,
      package_name: 'ผู้ใช้ทั่วไป',
      users: 1,
      icon: 'person'
    },
    {
      package_price: 1,
      package_name: 'องค์กรขนาดเล็ก',
      users: 5,
      icon: 'store'
    },
    {
      package_price: 1,
      package_name: 'องค์กรขนาดกลาง',
      users: 10,
      icon: 'apartment'
    },
    {
      package_price: 1,
      package_name: 'องค์กรขนาดใหญ่',
      users: 20,
      icon: 'source_environment'
    },
  ]
  @Input('model') set item(v: System) {
    this.modelItem = v;
    console.log(this.modelItem);

    if (localStorage.getItem('basket')) {
      let model: any = localStorage.getItem('basket')
      JSON.parse(model).forEach((f: BasketItems) => {
        this.actionOrders.push(f)
      })
    }
    this.getPackageClass();

    // this.packageItem = { items: [] };
    // this.packages.forEach((f: PackageItems) => {
    //   this.packageItem.items.push({
    //     package_price: f.package_price,
    //     package_name: f.package_name,
    //     users: f.users,
    //     icon: f.icon,
    //   })
    // })
  }

  @Output('update') updateItem = new EventEmitter()
  constructor(private router: Router,
    private http: HttpClient) { }
  orders: number = 0
  actionOrders: BasketItems[] = []
  actionLicense: PackageItems | undefined;
  useLicense(item: PackageItems) {
    this.actionLicense = item;
    this.licenseAmount = 1
    this.packageAmount = this.actionLicense.Package_license
    this.priceAmount = this.actionLicense.Package_price
  }

  classItems!: PackageHeader[]
  getPackageClass() {
    this.http.get(`package/headerview/${this.modelItem?.System_id}`).subscribe({
      next: (res: any) => {
        this.classItems = res.data
        this.sortAction = this.classItems[0].Class
        this.getPackage();
      }
    })
  }

  getPackage() {
    this.http.get(`package/view/${this.modelItem?.System_id}/${this.sortAction}`).subscribe({
      next: (res: any) => {
        // console.log(res.item);
        this.packageItem = res
        console.log(this.packageItem);
        this.packageItem.items.forEach((f: PackageItems) => {
          f.Package_logo = environment.contentPackage + '/' + f.Package_logo
        })

      }
    })
  }

  licenseAmount: number = 1;
  packageAmount: number = 0
  priceAmount: number = 0
  updateAmount(v: any) {
    if (!this.actionLicense) return
    this.licenseAmount = v.value
    this.packageAmount = this.actionLicense.Package_license * this.licenseAmount
    this.priceAmount = this.actionLicense.Package_price * this.licenseAmount
  }

  addBasket() {
    if (localStorage.getItem('basket')) localStorage.removeItem('basket')
    for (let i = 0; i < this.licenseAmount; i++) {
      let model: BasketItems = {
        systemId: this.modelItem?.System_id,
        packageId: this.actionLicense?.Package_id,
        system_name: this.modelItem?.System_name,
        package_name: this.actionLicense?.Package_name,
        package_price: this.actionLicense?.Package_price,
        license: this.actionLicense?.Package_license,
        package_type: this.actionLicense?.Package_type,
        price: this.actionLicense?.Package_price
      }
      this.actionOrders.push(model)
    }
    this.actionLicense = undefined
    localStorage.setItem('basket', JSON.stringify(this.actionOrders))
    console.log(this.actionOrders);

  }

  viewBasket() {
    this.router.navigate(['/', AppURL.basket])
  }

  useSort(sort: number) {
    this.sortAction = sort
    this.getPackage()
  }

  back() {
    this.updateItem.emit(false)
  }
}
