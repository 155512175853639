import { HttpClient } from '@angular/common/http';
import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { AppService } from 'src/app/services/app.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  sidebar: any[] = []
  contentHeight: number = 0;
  boxMenu: number = 0
  list: any[] = []
  constructor(public app: AppService,
    private menu: MenuService,
    private router: Router,
    private http: HttpClient) {
    this.checkSizePage(window.outerWidth)
    this.getMenuItem()

    this.router.events.subscribe({
      next: (res: any) => {
        if (res.snapshot?.routeConfig) {
          if (this.list.length == 0) this.list.push(res.snapshot?.routeConfig?.path)
          if (!this.list.find((fn: any) => fn == res.snapshot?.routeConfig?.path)) this.list.push(res.snapshot?.routeConfig?.path)
          this.activeMenu()
        }
      }
    })


  }
  menuItem: any;
  getMenuItem() {
    this.http.get(`Menus`).subscribe({
      next: ((res: any) => {
        res.data.forEach((f: any) => {
          if(f.Sub) f.sub = JSON.parse(f.Sub)
        })
        this.menuItem = res.data
      })
      , complete: () => this.activeMenu()
    })
  }

  activeMenu() {
    this.list.forEach(f => {
      let menu = this.menuItem?.find((fn: any) => fn.url == f)
      if (menu) {
        menu.active = true
        if (menu.sub) this.list.forEach((f: any) => {
          let sub = menu.sub.find((sb: any) => sb.url == f)
          if (sub) sub.active = true
        })
      }
    })
  }

  checkSizePage(width: any) {
    if (width < 431) {
      this.boxMenu = 100
      this.contentHeight = 87
    }
    else {
      this.boxMenu = 87
      this.contentHeight = this.boxMenu - 10
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkSizePage(event.target.outerWidth)

  }

  viewMenu() {
    this.app.menuAction = true;
  }

  closeMenu() {
    this.app.menuAction = false;
  }

  toURL(item: any) {
    if (!item.sub && item.active) {
      item.active = false;
      this.reloadItem(item)
    }
    else {
      if (item.sub) {
        this.reloadItem(item);
        item.active = true

      }
      else {
        this.reloadItem(item)
        if (item.Url) {
          this.list = []
          this.router.navigate(['/', item.Url])
        }
      }
    }
  }

  toSub(item: any, sub: any) {
    console.log(item.sub);

    this.reloadSub(item.sub)
    sub.active = true;
    this.list = []
    console.log(item.Url);
    console.log(sub.SubUrl);

    this.router.navigate(['/', item.Url, sub.SubUrl])

  }

  reloadSub(item: any) {
    item.forEach((f: any) => f.active = false)
    this.menuItem.forEach((f: any) => f.active = false)
  }

  reloadItem(item: any) {
    if (item.sub) this.menuItem.forEach((f: any) => {
      f.active = false
      if (f.sub) {
        if (f.sub.filter((fl: any) => fl.active == true).length > 0) {
          let sub = f.sub.find((fn: any) => fn.active == true)
          this.list = []
          this.router.navigate(['/', f.Url, sub.SubUrl])
        }
      }

    });
    else this.menuItem.forEach((f: any) => {
      f.active = false
      if (f.sub) f.sub.forEach((sub: any) => {
        sub.active = false;
        f.active = false
      })

    });
  }

}
