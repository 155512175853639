
<div class="container" *ngIf="!viewPackage?.System_status">
    <div class="row">
        <div class="col-6">
            <h1>PACKAGE</h1>
        </div>
        <div class="col-6 text-end" *ngIf="actionOrders.length > 0">
            <button pButton class="p-button-text p-button-sm p-button-raised" (click)="viewBasket()">
                <span class="basket-number me-1"> {{actionOrders.length}}</span>
                <span class="material-symbols-outlined">
                    shopping_cart
                </span>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-12 text-center" *ngFor="let sys of SystemItems">
            <div class="shadow  p-3 mt-3 box-package" (click)="view(sys)">
                <div class="box-image text-center ">
                    <img [src]="sys.System_logo">
                </div>
                <p><b>{{sys.System_name}}</b></p>
            </div>
        </div>
    </div>
</div>


<app-pk-detail *ngIf="viewPackage && viewPackage.System_status" [model]="viewPackage"
    (update)="update($event)"></app-pk-detail>