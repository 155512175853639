<div class="container">
    <div class="row">
        <div class="col-xl-2 col-lg-2 col-md-6 col-12">
            <button class="btn btn-primary p-5">primary</button>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-12">
            <button class="btn btn-secondary ms-2 p-5">secondary</button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-xl-2 col-lg-2 col-md-6 col-12 mt-2 text-center">
            <div class="shadow  p-5 text-center">
                <span class="material-symbols-outlined">
                    home
                </span>
                <p>Business</p>
            </div>
            <br>
            <h3>DEFAULT</h3>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-12 mt-2 text-center">
            <div class="shadow secondary p-5  text-center">
                <span class="material-symbols-outlined">
                    home
                </span>
                <p>Business</p>
            </div>
            <br>
            <h3>SECONDARY</h3>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-12 mt-2 text-center">
            <div class="shadow primary p-5 text-center">
                <span class="material-symbols-outlined">
                    home
                </span>
                <p>Business</p>
            </div>
            <br>
            <h3>PRIMARY</h3>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-12 mt-2  text-center">
            <div class="shadow action p-5 text-center">
                <span class="material-symbols-outlined">
                    home
                </span>
                <p>Business</p>
            </div>
            <br>
            <h3>ACTION</h3>
        </div>
    </div>
    <br><br><br>
</div>