import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppURL } from 'src/app/app.url';
import { sysTm } from 'src/app/pages/company/company.interface';
import { AlertService } from 'src/app/services/alert.service';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { UseRevokeLincens } from './use-license.interfeace';

@Component({
  selector: 'app-use-license',
  templateUrl: './use-license.component.html',
  styleUrls: ['./use-license.component.scss']
})
export class UseLicenseComponent {

  og: string = ""
  systemcode: string = ""
  system: string = ""
  systemAction: any = ""
  constructor(private http: HttpClient,
    private app: AppService,
    private ac: ActivatedRoute,
    private router: Router,
    private alert: AlertService
  ) {
    this.app.viewPage = false
    this.og = this.ac.snapshot.params['og']
    this.systemcode = this.ac.snapshot.params['systemid']
    if (this.og && this.systemcode) {
      this.getLicense()
      this.getSystem();
    }
  }

  BackSystem: any[] = []

  active: boolean = false
  model: any
  message: string = ""
  useLicens(v: any) {
    this.model = {
      license: v.value
    }
    this.http.post(`useLicense/${this.systemcode}/${this.og}`, this.model).subscribe({
      next: (res: any) => {
        this.active = res.data
        this.message = res.message
        this.licensekey = v.value
        this.alert.notify(res.message, 'success')
      },
      error: (err) => {
        this.alert.dialog('', err.error.message, 'warning')
        this.message = err.error.message
      }
    })
  }

  logo: string = ""
  getSystem() {
    this.http.get(`system/${this.systemcode}`).subscribe({
      next: (res: any) => {
        this.logo = environment.contentPackage + res.data[0].System_logo
        this.BackSystem = res.data
      }
    })
  }

  licensekey: string = ""
  getLicense() {
    this.http.get(`getLicense/og/${this.og}/${this.systemcode}`).subscribe({
      next: (res: any) => {
        this.licensekey = res.data.LicenseKey
      }
    })
  }

  ViewLicense() {
    this.router.navigate(['/', AppURL.license, this.systemcode, this.og])
  }

  buyPackage() {
    this.router.navigate(['/', AppURL.onsidepackage, this.systemcode, this.og])
  }

  toBack() {
    this.system = this.BackSystem.find((fn: any) => fn.System_id == this.systemcode).System_url
    window.location.replace(`${this.system}`)
  }

  RevokeLincens!: UseRevokeLincens
  revoke() {
    this.alert.confirm('', 'If the license is withdrawn, it cannot be used for 24 hours. Do you want to withdraw it?').then(fm => {
      if (fm.isConfirmed) {
        this.RevokeLincens = {
          License: this.licensekey
        }
        this.http.post(`revokeLicensebyid`, this.RevokeLincens).subscribe({
          next: (res: any) => {
            this.alert.notify(res.message, 'success')
          }, complete: () => {
            this.getLicense();
          }
        })
      }
    })
  }
}
