import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { profile } from './profile.interface';
import { KeycloakService } from 'keycloak-angular';
import { AlertService } from 'src/app/services/alert.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  profile!: profile
  constructor(private http: HttpClient,
    private kc: KeycloakService,
    private clipboard: Clipboard,
    private alert: AlertService
  ) {
    this.getProfile()
  }

  getProfile() {
    this.http.get("Profile").subscribe({
      next: (res: any) => {
        this.profile = res.data
      }
    })
  }

  copyText(){
    this.clipboard.copy(this.profile.Sub);
    this.alert.notify('Copy ID Successfull','success')
  }

  logout() {
    this.kc.logout()
  }
}
