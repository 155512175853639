import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import Cookies from 'universal-cookie';
import { Request } from 'express';

@Injectable({ providedIn: 'root' })
export class AppService {
  private _cookie: Cookies;
  viewPage: boolean = true;
  menuAction: boolean = true;
  contactAction: boolean = false;
  constructor(
    public title: Title,
    public meta: Meta,
    @Inject(PLATFORM_ID) private _platform: any,
    @Optional() @Inject(REQUEST) private _request: Request | null,
    @Optional() @Inject(RESPONSE) private _response: Request | null,
  ) {
    if (this.isServer) this._cookie = new Cookies(this._request?.headers.cookie);
    else this._cookie = new Cookies();
  }

  /** get cookie */
  get cookie() { return this._cookie; }

  /** is browser platform */
  get isBrowser() { return isPlatformBrowser(this._platform); }

  /** is server platform */
  get isServer() { return isPlatformServer(this._platform); }

  /** get request from nodejs express */
  serverStatusCode(status: number) {
    if (!this._response) return;
    this._response.statusCode = status;
  }

}
