import { Component } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { companyItem } from '../company/company.interface';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { AppURL } from 'src/app/app.url';

@Component({
  selector: 'app-to-system',
  templateUrl: './to-system.component.html',
  styleUrls: ['./to-system.component.scss']
})
export class ToSystemComponent {
  system: string = ""
  constructor(private app: AppService,
    private http: HttpClient,
    private ac: ActivatedRoute,
    private kc: KeycloakService,
    private router: Router) {
    this.system = this.ac.snapshot.queryParams['system']
    // console.log(this.system);

    this.app.viewPage = false;
    this.getCompany();
  }

  companyItem!: companyItem[];
  getCompany() {
    this.http.get(`organizations`).subscribe({
      next: (res: any) => {
        this.companyItem = res.data;
        this.companyItem.forEach((f: companyItem) => {
          if (f.Organization_logo) f.Organization_logo = environment.contentOG + '/' + f.Organization_logo
        })

      }
    })
  }

  toSystem(cm: companyItem) {
    let URL: string = this.system + '/check?og=' + cm.Organization_id
    window.location.replace(URL)

  }

  actionView: boolean = false
  addOrganization() {
    this.actionView = true
  }

  updateItem(e: any) {
    this.getCompany();
    this.actionView = false
  }

  close() {
    this.actionView = false
  }

  ToDashbaord() {
    this.app.viewPage = true;
    this.router.navigate(['/', AppURL.organization])
  }


  logout() {
    this.kc.logout()
    window.location.replace(this.system)
  }
}


