<div class="box-upload" (click)="upload.click()">
    <input type="file" class="d-none" #upload (change)="uploadFile($event)" />
    <img
      *ngIf="modelImage && modelImage?.type != 'pdf'"
      class="d-none"
      #image
      [src]="modelImage?.base64"
      alt=""
      (load)="checkImage(image)" />
    <ng-content></ng-content>
  </div>
  