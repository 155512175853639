<div class="container">
    <div class="row mt-2">
        <div class="col-12">
            <h3> Manage Role</h3>
        </div>
        <div class="col-12">
            <p>
                <input type="checkbox" value="1" [checked]="modelItems.Role == 1" (click)="actionRole($event)">
                ADMIN
            </p>
            <p>
                <input type="checkbox" value="2" [checked]="modelItems.Role == 2" (click)="actionRole($event)">
                USER
            </p>
        </div>
    </div>
</div>