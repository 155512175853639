<div class="container">
    <div class="row p-0 m-0">
        <div class="col-6 mb-3">
            <button pButton label="BACK" class="p-button-text p-button-sm p-button-raised" (click)="back()">
                <span class="material-symbols-outlined">
                    arrow_back
                </span>
            </button>
        </div>
        <div class="col-6 text-end" *ngIf="actionOrders.length > 0">
            <button pButton class="p-button-text p-button-sm p-button-raised" (click)="viewBasket()">
                <span class="basket-number me-1"> {{actionOrders.length}}</span>
                <span class="material-symbols-outlined">
                    shopping_cart
                </span>
            </button>
        </div>
    </div>
    <div class="row p-0 m-0">
        <div class="col-xl-4 col-lg-4 col-md-6 col-12 text-center ">
            <div class="box-item p-3">
                <div class="box-image text-center ">
                    <img [src]="modelItem?.System_logo">
                </div>
                <p><b>{{modelItem?.System_name}}</b></p>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-12 text-center box-sort">
            <button *ngFor="let cl of classItems" class="btn" [ngClass]="{'btn-sec':sortAction == cl.Class}"
                (click)="useSort(cl.Class)">
                <span>{{cl.Class}} Month</span>
            </button>
            <!-- <button class=" btn" [ngClass]="{'btn-sec':sortAction == 1}" (click)="useSort(1)">Month</button> -->
            <!-- <button class="btn" [ngClass]="{'btn-sec':sortAction == 12}" (click)="useSort(12)">Year</button> -->
        </div>
    </div>
    <div class="row p-0 m-0">
        <div class="col-xl-4 col-lg-4 col-md-6 col-12 text-center" *ngFor="let pk of packageItem.items">
            <div class="shadow  p-3 mt-5 box-package" (click)="useLicense(pk)">
                <!-- <div class="box-image">
                    <img [src]="pk.Package_logo" alt="">
                </div>
                <br> -->
                <!-- <span>{{pk?.Package_name}}</span> -->
                <h2>{{pk?.Package_name}}</h2>
                <hr>
                <h3>{{pk?.Package_price | number : '1.2-2'}} THB</h3>
                <p>{{pk?.Package_license}} License </p>
                <p>Each license is valid for {{pk.Package_type}} month.</p>
                <button class="btn btn-primary">Get This Plan</button>
            </div>
        </div>
    </div>
    <br><br><br>

    <p-dialog header="License" *ngIf="actionLicense" [(visible)]="actionLicense" [breakpoints]="{ '960px': '90vw' }"
        [style]="{ width: '50vw' }">
        <div class="row">
            <div class="col-12 text-center">
                <!-- <div class="box-image">
                    <img [src]="actionLicense.Package_logo" alt="">
                </div>
                <br> -->
                <!-- <span>{{actionLicense.Package_name}}</span>
                <p>{{actionLicense.Package_license}} Lincens / {{actionLicense.Package_price}} THB</p> -->
                <h2>{{actionLicense.Package_name}}</h2>
                <hr>
                <h3>{{actionLicense.Package_price | number : '1.2-2'}} THB</h3>
                <p>{{actionLicense.Package_license}} License </p>
                <p>Each license is valid for {{actionLicense.Package_type}} month.</p>
                <!-- <button class="btn btn-primary">Get This Plan</button> -->
                <input type="number" [value]="licenseAmount" placeholder="1" pInputText (input)="updateAmount(am)" #am>
                <br>
                <br>
                {{packageAmount}} Lincens = {{priceAmount | number:'1.2-2'}} THB
                <br><br>
            </div>
            <div class="col-12 text-center ">
                <!-- <button pButton label="Buy Now" class="p-button-text p-button-sm p-button-raised" (click)="addBasket()">
                    <span class="material-symbols-outlined me-2">
                        payments
                        </span>
                </button> -->
                <button pButton label="Add Basket" class="ms-3 p-button-text p-button-sm p-button-raised"
                    (click)="addBasket()">
                    <span class="material-symbols-outlined me-2">
                        shopping_cart
                    </span>
                </button>
            </div>
        </div>
    </p-dialog>
</div>