<div class="box-sidebar">
    <div class="box-logo">
        <img width="100%" src="./assets/logo/package.png" alt="">
    </div>
    <br>
    <div class="box-close">
        <span class="material-symbols-outlined close" (click)="closeSide()">
            close
        </span>
    </div>
    <br>
    <ul>
        <li *ngFor="let menu of menuItem">
            <div class="box-item text-center rd m-action" [ngClass]="{'action': menu.active == true}"
                (click)="toURL(menu)">
                <span class="material-symbols-outlined">
                    {{menu.Icon}}
                </span>
                <p class="text-name">{{menu.Name}}</p>
            </div>
        </li>
    </ul>
</div>