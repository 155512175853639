<div class="container">
    <div class="row g-3 justify-content-center ">
        <div class="col-xl-4 col-lg-4 col-12 text-center text-danger">
            <!-- <h1>ใส่ LOGO เกี่ยวกับ License ให้มันไม่ดูว่าง</h1>
            <div class="box-logo"></div> -->
            <img width="100%" [src]="logo" alt="">
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-4 col-12 ">
            <h3>License</h3>
            <div class="input-icon-end">
                <input pInputText class="w-100" placeholder="Fill out the license" [value]="licensekey"
                    [disabled]="licensekey" (change)="useLicens(license)" #license />
                <!-- <span class="material-symbols-outlined" *ngIf="this.active && model">
                    check
                </span> -->
                <span class="material-symbols-outlined" *ngIf="!this.active && model">
                    priority_high
                </span>
                <span class="m-action" *ngIf="licensekey">
                    <b class="revoke" (click)="revoke()">REVOKE</b>
                </span>
            </div>
            <span *ngIf="!this.active && model" class="text-danger ">{{message}}</span>
        </div>
    </div>
    <div class="row mb-3 mt-3 justify-content-center">
        <div class="col-xl-4 col-lg-4 col-12">
            <span class="text-decoration-underline m-action me-3" (click)="ViewLicense()">Available License</span>
            <span class="text-decoration-underline m-action" (click)="buyPackage()">Buy License</span>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-4 col-12">
            <button class="btn btn-dark w-100 " (click)="toBack()">
                BACK
            </button>
        </div>
    </div>
    <br><br><br>
</div>