<div class="container" [formGroup]="form">
    <div class="row">
        <div class="col-12 text-center">
            <div class="box-image" [ngClass]="{'active':viewImage}">
                <app-upload-file (FileUpload)="uploadFile($event)" [width]="1024" [height]="1024">
                    <ng-container *ngIf="!viewImage">
                        <span class="material-symbols-outlined fs-1 mt-3">
                            image
                        </span>
                        <br>
                        <span>Logo</span>
                    </ng-container>
                    <img *ngIf="viewImage" [src]="viewImage" alt="">

                </app-upload-file>
            </div>
            <span>1024 x 1024</span>
            <br><br>
        </div>
    </div>
    <div class="row g-3 justify-content-center ">
        <div class="col-12" *ngIf="form.value['Organization_id']">
            <p>Organization ID</p>
            <div class="input-icon-end">
                <input pInputText class="w-100 " formControlName="Organization_id" readonly />
                <span class="material-symbols-outlined ms-2 m-action" (click)="copyText(form.value['Organization_id'])">
                    content_copy
                </span>
            </div>
        </div>
        <div class="col-12">
            <!-- <span class="p-float-label">
              
                <label for="username">Organization Name</label>
            </span> -->
            <p>Organization Name</p>
            <div class="input-icon-end">
                <input pInputText class="w-100 " formControlName="Organization_name" />
            </div>
        </div>
    </div>
    <div class="row justify-content-center mt-3">
        <div class="col-lg-4 col-lg-4 col-md-4 col-12 text-center">
            <button class="btn btn-secondary lg rounded icon-auto w-100 justify-content-center" *ngIf="modelItem"
                (click)="update()">
                <span class="material-symbols-outlined me-2">
                    edit
                </span>
                Update
            </button>
            <button class="btn btn-primary lg rounded icon-auto w-100 justify-content-center" *ngIf="!modelItem"
                (click)="create()">
                <span class="material-symbols-outlined me-2">
                    add
                </span>
                Create
            </button>
            <!-- <p-button label="Update" *ngIf="modelItem" (onClick)="update()"></p-button>
            <p-button label="Create" *ngIf="!modelItem" (onClick)="create()"></p-button> -->
        </div>
    </div>
</div>