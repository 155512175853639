import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Finduser, Lincens, Members, MembersArray, RevokeLincens, temsItems } from './team.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { AppURL } from 'src/app/app.url';
import { AlertService } from 'src/app/services/alert.service';


@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent {
  ogItems!: temsItems
  // @Input('model') set model(v: any) {
  //   this.ogItems = v
  //   if (this.ogItems) {
  //     this.getRole()

  //     this.getTeams()
  //   }

  // }

  ogId: string = ""
  constructor(private http: HttpClient,
    private ac: ActivatedRoute,
    private router: Router,
    private alert: AlertService
  ) {
    this.ogId = this.ac.snapshot.params['ogid']
    if (this.ogId) {
      this.getRole()
      this.getTeams()
    }
  }

  userItems!: Members[]
  getTeams() {
    this.http.get(`team/${this.ogId}`).subscribe({
      next: (res: any) => {
        this.userItems = res.data
      }
    })
  }

  roleItems: any
  getRole() {
    this.http.get(`team/Role/${this.ogId}`).subscribe({
      next: (res: any) => {
        this.roleItems = res.data.Role
      }
    })
  }




  findItems!: Finduser
  find: boolean = false
  findUser(userid: any) {
    this.http.get(`team/find/${userid.value}/${this.ogId}`).subscribe({
      next: (res: any) => {
        this.findItems = res.data
        this.find = true
      },
      error: () => {
        this.find = false
      },
      complete: () => {

      }
    })
  }


  addTeam(e: any) {
    e.value = ""
    let model = {
      userid: this.findItems.Id
    }
    this.http.post(`team/add/${this.ogId}`, model).subscribe({
      next: (res) => {
        this.getTeams()
        this.find = false
        this.actionView = false
        this.alert.notify('Add Team Successfull', 'success')
      }
    })
  }

  viewItem(item: Members) {
    if (item.View == true) {
      item.View = false;
      return
    }
    this.userItems.forEach((f: Members) => {
      f.View = false
    })
    item.View = !item.View
  }

  backTo() {
    this.router.navigate(['/', AppURL.organization])
  }

  ogRoleItems!: any
  OgRole(item: Members) {
    this.close()
    this.textHeader = "Organization Role"
    this.ogRoleItems = item
    this.actionView = true
  }

  sysRoleItems!: any
  SysRole(item: Members) {
    this.close()
    this.textHeader = "System Role"
    this.sysRoleItems = item
    this.actionView = true
  }


  viewLicens!: any
  ViewLicense(item: Members) {
    this.close()
    this.textHeader = "System License"
    this.viewLicens = item
    this.viewLicens.manageRole = this.roleItems
    this.actionView = true
  }

  textHeader: string = ""
  actionView: boolean = false;

  close() {
    this.ogRoleItems = ""
    this.sysRoleItems = ""
    this.viewLicens = ""
    this.actionView = false
  }

  update(v: Members) {
    if (v) {
      this.getTeams()
      this.close()
    }
  }

  RemoveUser(item: Members) {
    this.alert.confirm('Want to delete a user?').then((fm) => {
      if (fm.isConfirmed) {
        this.http.delete(`team/delete/${item.Userid}/${this.ogId}`).subscribe({
          next: (res: any) => {
            this.getRole()
            this.getTeams()
            this.alert.notify(res.message, 'success')
          },
          error: (err) => {
            this.alert.notify(err.error.message, 'warning')
          }
        })
      }
    })

  }

  RevokeLincens!: RevokeLincens
  revoke(member: Members, ln: Lincens) {
    this.alert.confirm('', 'If the license is withdrawn, it cannot be used for 24 hours. Do you want to withdraw it?').then(fm => {
      if (fm.isConfirmed) {
        this.RevokeLincens = (<RevokeLincens>{
          Userid: member.Userid,
          License: ln.License,
        })
        this.http.post(`revokeLicense`, this.RevokeLincens).subscribe({
          next: (res: any) => {
            this.alert.notify(res.message, 'success')
          }, complete: () => {
            member.Lincens.splice(member.Lincens.findIndex((del: any) => del.License == ln.License), 1)
          }
        })
      }
    })
  }


}
