import { Component, Input } from '@angular/core';
import { Members, RevokeLincens } from '../team.interface';
import { HttpClient } from '@angular/common/http';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-sys-license',
  templateUrl: './sys-license.component.html',
  styleUrls: ['./sys-license.component.scss']
})
export class SysLicenseComponent {
  modelItems!: Members
  @Input('model') set model(v: Members) {
    this.modelItems = v;
    console.log(this.modelItems);

  }

  constructor(private http: HttpClient,
    private alert: AlertService
  ) {

  }

  RevokeLincens!: RevokeLincens
  revoke(item: any) {
    this.alert.confirm('', 'If the license is withdrawn, it cannot be used for 24 hours. Do you want to withdraw it?').then(fm => {
      if (fm.isConfirmed) {
        this.RevokeLincens = (<RevokeLincens>{
          Userid: this.modelItems.Userid,
          License: item.License,
        })
        this.http.post(`revokeLicense`, this.RevokeLincens).subscribe({
          next: (res: any) => {
            this.alert.notify(res.message, 'success')
          }, complete: () => {
            this.modelItems.Lincens.splice(this.modelItems.Lincens.findIndex((del: any) => del.License == item.License), 1)
          }
        })
      }
    })


  }
}
