import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppURL } from 'src/app/app.url';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-check-payment',
  templateUrl: './check-payment.component.html',
  styleUrls: ['./check-payment.component.scss']
})
export class CheckPaymentComponent {
  invoice: string = ""
  systemid: string = ""
  ogid: string = ""
  constructor(private ac: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private app: AppService
  ) {
    this.invoice = this.ac.snapshot.queryParams['mch_order_no']
    this.systemid = this.ac.snapshot.params['systemid']
    this.ogid = this.ac.snapshot.params['ogid']
    if (this.ogid && this.systemid) {
      this.app.viewPage = false;
    }

    if (!this.invoice) window.location.replace('https://dashboard.legitid.org/payment/success?mch_order_no=20240400060')
    this.checkInvoice();
  }

  checkInvoice() {
    this.http.get(`checkInvoice/${this.invoice}`).subscribe({
      next: (res) => {
        localStorage.removeItem('basket')
      }
    })
  }

  viewLicense() {
    if (this.ogid && this.systemid) this.router.navigate(['/', AppURL.license, this.systemid, this.ogid])
    else this.router.navigate(['/', AppURL.license])
  }
}
