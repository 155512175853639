import { Component } from '@angular/core';
import { BasketItems, Package, System, SystemList } from './package';
import { AppURL } from 'src/app/app.url';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent {
  constructor(private router: Router,
    private http: HttpClient) {
    if (localStorage.getItem('basket')) {
      let model: any = localStorage.getItem('basket')
      JSON.parse(model).forEach((f: BasketItems) => {
        this.actionOrders.push(f)
      })
    }
    this.getSystem()
  }
  actionOrders: any[] = [];
  package: any = [] = [
    { logo: '../assets/logo/POS_0.png', name: 'POS', url: '', status: false },
    { logo: '../assets/logo/Booking X_0.png', name: 'Booking', url: '', status: false },
    { logo: '../assets/logo/NLChat_0.png', name: 'NLChat', url: '', status: false },
    { logo: '../assets/logo/Biz soft_0.png', name: 'Bizsoft', url: '', status: false },
    { logo: '../assets/logo/SURVEY BOX_0.png', name: 'Survey', url: '', status: false },
    { logo: '../assets/logo/DeeAuction_0.png', name: 'DeAuction', url: '', status: false },
  ]

  SystemItems!: System[]
  getSystem() {
    this.http.get(`system`).subscribe({
      next: (res: any) => {
        this.SystemItems = res.data
        this.SystemItems.forEach(f => {
          if (f.System_logo) f.System_logo = environment.contentPackage + f.System_logo
        })
      }
    })
  }

  viewPackage!: System;
  viewItem: boolean = false
  view(item: System) {
    this.viewPackage = item
    this.viewPackage.System_status = true;

  }

  update(v: any) {
    this.viewPackage.System_status = false;
  }


  viewBasket() {
    this.router.navigate(['/', AppURL.basket])
  }
}

