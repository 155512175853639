<div class="container">
    <div class="row mt-2">
        <div class="col-12">
            <h3> Manage Role</h3>
        </div>
        <div class="col-12">
            <div class="box-system" *ngFor="let sys of systemItems">
                <input type="checkbox" [checked]="sys.System_view" (click)="actionSystemRole(sys)">
                <img *ngIf="sys.System_logo" width="100" class="ms-3" src="{{content}}/{{sys.System_logo}}" alt="">
                <!-- <br> -->
                <!-- {{sys.System_name}} -->
            </div>
        </div>
        <div class="col-12">
            <button class="btn btn-primary lg w-100  text-center icon-auto justify-content-center "
                (click)="updateRole()">
                <span class="material-symbols-outlined me-3">
                    view_cozy
                </span>
                Update Role System
            </button>
        </div>
    </div>
</div>