<div class="row">
    <div class="col-12 text-center ">
        <div class="box-image text-center">
            <img [src]="sys.service_logo">
        </div>
        <p><b>{{sys.service_name}}</b></p>
        <br>
        <br>
        <select class="form-select">
            <option>Select License</option>
            <option *ngFor="let lc of license">{{lc?.name}}</option>
        </select>
        <br>
        <br>
        <select class="form-select">
            <option>Select Team</option>
            <option *ngFor="let tm of team">{{tm?.name}}</option>
        </select>
    </div>
    <div class="col-12 mt-3 text-end">
        <p-button label="Assign" styleClass="p-button-sm  p-button-text">
            <span class="material-symbols-outlined">
                add
            </span>
        </p-button>
    </div>
</div>