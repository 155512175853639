import { Injectable } from '@angular/core';
import { AccessToken, IUserLogin } from '../interfaces/auth.interface';
import { Observable, Subscription, map, of, tap, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '../interfaces/http.interface';
import { AppService } from './app.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { KeycloakService } from 'keycloak-angular';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _keyUserLogin: string = 'us.lg';
  private _keyAccessToken: string = 'tk.app';
  private _evtAccessToken?: Subscription;

  constructor(
    private _http: HttpClient,
    private _app: AppService,
    private _state: TransferState,
    private kc: KeycloakService
  ) { }


  tokenItem: any

  setTo(url: string, status?: boolean) {
    if (status) { window.location.href = url; return }
    else {
      let model = {
        access_token: localStorage.getItem('access_token'),
        refresh_token: localStorage.getItem('refresh_token')
      }

      this._http.post('/code/gCode', model).subscribe({
        next: (res: any) => {
          let urlTo = `${url}/check?key=${res.data}`
          window.location.href = urlTo
        }
      })
    }
  }

  userItem: any;
  getUser() {
    this._http.get(`/api/profile`).subscribe({
      next: (res: any) => {
        this.userItem = res;
      }
    })
  }

  uuid: any;
  access_token: any;
  refresh_token: any;
  setToken(item?: any) {
    if (item) {
      this.access_token = item?.access_token;
      this.refresh_token = item?.refresh_token;
    }
    localStorage.setItem('access_token', item?.access_token);
    localStorage.setItem('refresh_token', item?.refresh_token);
    setTimeout(() => this.getToken(), 200);

  }


  getToken() {
    this.access_token = localStorage.getItem('access_token');
    this.refresh_token = localStorage.getItem('refresh_token');
  }

  generateRefreshToken() {
    // let refresh_token = localStorage.getItem('refresh_token');
    // let model = { refresh_token: refresh_token };
    // return this._http.post(`${environment.apiUrl}/api/refresh_token`, model);
    let refreshToken = new Observable((res) => {
      this.kc.updateToken(100)
      this.kc.getToken().then((token: any) => {
        res.next(token)
      })
    })

    // let refresh_token = localStorage.getItem('refresh_token');
    // let model = { refresh_token: refresh_token };
    return refreshToken
  }



}
