<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="d-flex ">
                <button class="btn btn btn-cancel lg me-3 icon-start" *ngIf="BackSystem" (click)="backPage()">
                    <span class="material-symbols-outlined">
                        arrow_left_alt
                    </span>
                    ฺBACK
                </button>
                <h1>The license you have</h1>

            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-responsive  table-hover " *ngIf="viewLincens">
                <ng-container *ngFor="let sys of lincensItems">
                    <ng-container *ngIf="sys.Header">
                        <thead>
                            <!-- <tr class="subHeader">
                            <th colspan="4">{{sys.Package}}</th>
                        </tr> -->
                            <tr class="subHeader">
                                <th [width]="350">( {{sys.Package}} ) Lincens</th>
                                <th
                                    class="text-lg-start text-md-start text-end text-end d-none d-lg-table-cell  d-md-table-cell">
                                    <span *ngIf="sys.View">Status</span>
                                </th>
                                <th class="d-none d-lg-table-cell d-md-table-cell">
                                    <span *ngIf="sys.View">Customer</span>
                                </th>
                                <th class="text-end d-none d-lg-table-cell">
                                    <span *ngIf="sys.View"> Period of Use</span>
                                </th>

                                <th class="text-end ">
                                    <div class="d-flex justify-content-end">
                                        <span *ngIf="sys.View" class="d-none d-lg-table-cell">Expire</span>
                                        <span *ngIf="!sys.View" class="material-symbols-outlined m-action"
                                            (click)="sys.View = true">
                                            chevron_right
                                        </span>
                                        <span *ngIf="sys.View" class="material-symbols-outlined m-action"
                                            (click)="sys.View = false">
                                            stat_minus_1
                                        </span>
                                    </div>
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let ls of sys.License">
                                <tr *ngIf="sys.View">
                                    <td>
                                        <div class="d-flex">
                                            <span class="text-license">{{ls.LicenseKey}} </span>
                                            <span class="material-symbols-outlined ms-2 m-action" *ngIf="ls.Active == 0"
                                                (click)="copyText(ls)">
                                                content_copy
                                            </span>
                                        </div>
                                        <span *ngIf="ls.Active == 1" class="d-table-cell d-lg-none d-xl-none d-md-none">
                                            <b class="text-success me-3">Active</b>
                                        </span>
                                        <span *ngIf="ls.Active == 2" class="d-table-cell d-lg-none d-xl-none d-md-none">
                                            <b class="text-info me-3">Freeze</b>
                                        </span>
                                        <!-- <span class="d-table-cell d-lg-none d-xl-none d-md-none">{{ls.Period}} Month</span> -->
                                        <span *ngIf="ls.Expire"
                                            class="d-table-cell d-lg-none d-xl-none d-md-none text-danger ">Expire
                                            {{ls.Expire || 'Never'}}</span>
                                    </td>
                                    <td
                                        class="text-lg-start text-md-start text-end text-end d-none d-lg-table-cell  d-md-table-cell">
                                        <span *ngIf="ls.Active == 1"><b class="text-success ">Active</b></span>
                                        <span *ngIf="ls.Active == 2"><b class="text-info ">Freeze</b></span>
                                        <span *ngIf="ls.Active == 3"><b class="text-danger ">Expire</b></span>
                                        <span *ngIf="ls.Active == 0"><b>Inactive</b></span>
                                    </td>
                                    <td class="d-none d-lg-table-cell d-md-table-cell">{{ls.UserActive}}</td>
                                    <td class="text-end d-none d-lg-table-cell"> <span>{{ls.Period}} Month</span> </td>
                                    <td class="text-end d-none d-lg-table-cell text-end">
                                        {{ls.Expire || 'Never'}}
                                    </td>
                                    <td class="text-end d-table-cell d-lg-none text-end"></td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </ng-container>
                </ng-container>
            </table>
            <h3 *ngIf="!viewLincens" class="text-danger ">No License ทำ ICON ให้ด้วย</h3>
        </div>
    </div>
</div>
<br><br><br>